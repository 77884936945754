.root {
	height: 100%;
}

.cta-container {
	height: 80%;
}

.cta-container-unset-height {
	height: 80%;
	background-color: #000352;
}

.cta-inner-container {
	padding: 0px 200px;
	background-color: #000352;
	height: 500px;
}

.logo {
	height: 100px;
	width: 300px;
}

.title {
	color: #d65200;
	text-transform: uppercase;
	margin: 20px 0px;
	padding-left: 10px;
	font-size: 24px;
	font-family: 'League Spartan', sans-serif;
}

.mobile-title {
	display: none;
}

.mobile-cta {
	display: none;
}

.mobile-step1-cta {
	display: none;
}

.cta {
	color: #ffffff;
	text-transform: uppercase;
	font-family: 'Open Sans';
	padding-left: 10px;
}

.dealerCode-card {
	height: 300px;
	width: 700px;
	margin: -200px auto;
	display: flex;
	justify-content: flex-end;
}

.dealerCode-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0px auto;
}

.dealerCode-label {
	text-align: center;
	font-size: 22px;
	color: #707070;
	font-family: 'Open Sans';
	font-weight: bold;
	margin: 30px auto;
}

.dealerCode-input-container {
	margin: 0px auto;
}

.dealerCode-input {
	width: 300px;
	margin: 0px auto;
}

.dealerCode-submit {
	margin-top: 30px !important;
	background-color: #000352 !important;
	color: #ffffff !important;
	width: 150px !important;
	margin: 30px auto !important;
}

.dealerCode-orange {
	background-color: #d65200;
	height: 300px;
	width: 40px;
}

.privacy {
	color: #ffffff;
	text-transform: uppercase;
	font-family: 'Open Sans';
	font-weight: bold;
	margin: 0px auto;
	font-size: 20px;
	text-align: center;
	width: 80%;
}

.MuiRating-iconFilled {
	color: #d65200;
}

.MuiRating-iconHover {
	color: #d65200;
}

/*-----Desktops-----*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.dealerCode-card {
		bottom: 325px;
	}
	.MuiTypography-h3 {
		font-size: 40px !important;
	}
}

/*-----Laptops-----*/
@media screen and (min-device-width: 800px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
	.logo {
		height: 75px;
		width: 225px;
	}

	.cta-inner-container {
		padding: 0px 100px;
	}

	.dealerCode-card {
		bottom: 400px;
		height: 250px;
		width: 500px;
	}

	.dealerCode-label {
		text-align: center;
		font-size: 18px;
		color: #707070;
		font-family: 'Open Sans';
		font-weight: bold;
		margin: 20px auto;
	}

	.dealerCode-input {
		width: 250px;
	}

	.MuiTypography-h3 {
		font-size: 28px !important;
	}
}

/*-----Tablets-----*/
@media screen and (min-device-width: 501px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
	.root {
		background-color: #000352;
	}

	.cta-container {
		height: 100%;
		background-color: #000352;
	}

	.cta-inner-container {
		height: 450px;
		padding: 0px 30px;
	}

	.logo {
		height: 75px;
		width: 225px;
	}

	.dealerCode-card {
		height: 250px;
		width: 500px;
		position: relative;
		bottom: 200px;
		margin-top: 0px;
	}

	.dealerCode-label {
		text-align: center;
		font-size: 18px;
		color: #707070;
		font-family: 'Open Sans';
		font-weight: bold;
		margin: 20px auto;
	}

	.dealerCode-input {
		width: 250px;
	}

	.MuiTypography-h3 {
		font-size: 24px !important;
	}
}

/*-----Mobile-----*/
@media screen and (min-device-width: 400px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
	.root {
		background-color: #000352;
	}

	.cta-container {
		height: 100%;
		background-color: #000352;
	}

	.cta-container-unset-height {
		height: unset;
		background-color: #000352;
	}

	.cta-inner-container {
		padding: 0px 30px;
	}

	.logo {
		height: 75px;
		width: 225px;
	}

	.dealerCode-card {
		height: 225px;
		width: calc(100% - 80px);
		margin-top: 5px;
		margin-left: 40px;
		position: relative;
		bottom: 250px;
	}

	.dealerCode-label {
		text-align: center;
		font-size: 18px;
		color: #707070;
		font-family: 'Open Sans';
		font-weight: bold;
		margin: 20px auto;
	}

	.dealerCode-input {
		width: 200px;
		margin: 0px auto;
	}

	.dealerCode-input > div > input {
		text-align: center !important;
		font-size: 20px !important;
	}

	.dealerCode-orange {
		background-color: #d65200;
		height: 300px;
		width: 20px;
	}

	.MuiTypography-h3 {
		font-size: 22px !important;
	}

	.MuiTypography-h5 {
		font-size: 18px !important;
	}

	.title {
		display: none;
	}

	.cta {
		display: none;
	}

	.mobile-cta {
		display: block;
		color: #ffffff;
		font-family: 'Open Sans';
		margin: 10px 0px;
		padding-left: 10px;
		text-transform: uppercase;
	}

	.mobile-title {
		display: block;
		color: #d65200;
		font-family: 'League Spartan', sans-serif;
		text-transform: uppercase;
		margin: 0px 0px 10px 0px;
		padding-left: 10px;
	}

	.mobile-step1-cta {
		display: block;
		color: #d65200;
		font-family: 'League Spartan', sans-serif;
		text-transform: uppercase;
		margin: 0px 0px 10px 0px;
		padding-left: 10px;
	}

	.dealerCode-orange {
		background-color: #d65200;
		height: 225px;
		width: 20px;
	}
}

@media screen and (min-device-width: 300px) and (max-device-width: 399px) and (-webkit-min-device-pixel-ratio: 1) {
	.root {
		background-color: #000352;
	}

	.dealerCode-card {
		height: 225px;
		width: calc(100% - 80px);
		margin-top: 5px;
		margin-left: 40px;
		position: relative;
		bottom: 250px;
	}

	.dealerCode-label {
		text-align: center;
		font-size: 14px;
		color: #707070;
		font-family: 'Open Sans';
		font-weight: bold;
		margin: 20px auto;
	}

	.dealerCode-input {
		width: 150px;
		margin: 0px auto;
	}

	.dealerCode-input > div > input {
		text-align: center !important;
		font-size: 20px !important;
	}

	.dealerCode-orange {
		background-color: #d65200;
		height: 300px;
		width: 20px;
	}

	.MuiTypography-h3 {
		font-size: 20px !important;
	}

	.MuiTypography-h5 {
		font-size: 18px !important;
	}

	.title {
		display: none;
	}

	.cta {
		display: none;
	}

	.mobile-cta {
		display: block;
		color: #ffffff;
		font-family: 'Open Sans';
		margin: 10px 0px;
		padding-left: 10px;
		text-transform: uppercase;
	}

	.mobile-title {
		display: block;
		color: #d65200;
		font-family: 'League Spartan', sans-serif;
		text-transform: uppercase;
		margin: 0px 0px 10px 0px;
		padding-left: 10px;
	}

	.mobile-step1-cta {
		display: block;
		color: #d65200;
		font-family: 'League Spartan', sans-serif;
		text-transform: uppercase;
		margin: 0px 0px 10px 0px;
		padding-left: 10px;
	}

	.dealerCode-orange {
		background-color: #d65200;
		height: 225px;
		width: 20px;
	}

	.cta-container {
		height: 100%;
		background-color: #000352;
	}

	.cta-inner-container {
		padding: 0px 30px;
	}

	.logo {
		height: 75px;
		width: 225px;
	}
}
