.confirmation-root {
	height: 100vh;
}

.confirmation-container {
	height: 80%;
	background-color: #000352;
}

.confirmation-inner-container {
	padding: 0px 200px;
}

.confirmation-logo {
	height: 100px;
	width: 300px;
}

.confirmation-title {
	color: #d65200;
	text-transform: uppercase;
	margin: 20px 0px;
	padding-left: 10px;
}

.confirmation-cta {
	color: #ffffff;
	text-transform: uppercase;
	font-family: 'Open Sans';
	padding-left: 10px;
}

.confirmation-content {
	display: flex;
	position: relative;
	justify-content: flex-start;
	padding-top: 75px;
}

.instructions-container {
	height: 400px;
	width: 800px;
	top: 100px;
	position: relative;
	background-color: #ffffff;
	z-index: 2;
	left: 210px;
	border: 1px solid #707070;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.orange {
	height: 400px;
	width: 40px;
	background-color: #d65200;
	position: relative;
	right: 20px;
}

.instructions {
	font-size: 32px !important;
	color: #707070 !important;
	padding: 0px 30px !important;
	width: 660px !important;
	text-align: center !important;
	font-family: 'Open Sans' !important;
}

.img-container {
	position: relative;
	left: 150px;
}

.road-img {
	height: 400px;
	width: 800px;
}

/*-----Desktops/Large Laptops-----*/

/*---Non-Retina Screens---*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.road-img {
		width: 600px;
	}

	.img-container {
		left: 100px;
	}

	.instructions-container {
		width: 600px;
		top: 175px;
	}

	.orange {
		right: 0px;
	}

	.MuiTypography-h3 {
		font-size: 38px !important;
	}
}

/*---Retina Screens---*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
}

/*-----Small Laptops/Chromebooks-----*/
@media screen and (min-device-width: 800px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
	.road-img {
		width: 600px;
	}

	.img-container {
		left: 100px;
	}

	.instructions-container {
		width: 600px;
		top: 175px;
	}

	.orange {
		right: 0px;
	}
}

/*-----Tablets-----*/
@media screen and (min-device-width: 500px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
	.confirmation-content {
		display: flex;
		position: relative;
		justify-content: center;
		padding-top: 100px;
	}

	.confirmation-container {
		height: 100%;
		background-color: #000352;
	}

	.instructions-container {
		height: 300px;
		width: 700px;
		top: 200px;
		position: relative;
		background-color: #ffffff;
		z-index: 2;
		left: 350px;
		border: 1px solid #707070;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
	}

	.orange {
		height: 300px;
		width: 40px;
		background-color: #d65200;
		right: 0px;
	}

	.instructions {
		font-size: 22px !important;
		color: #707070 !important;
		padding: 20px 20px !important;
		width: 500px !important;
		text-align: center !important;
		font-family: 'Open Sans' !important;
	}

	.img-container {
		position: relative;
		right: 290px;
		left: unset;
	}

	.road-img {
		height: 300px;
		width: 700px;
	}

	.confirmation-inner-container {
		padding: 0px 50px;
	}
}

/*-----Mobile-----*/
@media screen and (min-device-width: 400px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
	.confirmation-inner-container {
		padding: 30px;
	}

	.confirmation-logo {
		height: 75px;
		width: 225px;
	}

	.road-img {
		display: none;
	}

	.img-container {
		display: none;
	}

	.instructions-container {
		height: 250px;
		width: calc(100% - 80px);
		position: relative;
		left: 0px;
		top: 0px;
		background-color: #ffffff;
		z-index: 2;
		border: 1px solid #707070;
		border-radius: 3;
		display: flex;
		justify-content: space-between;
	}

	.orange {
		height: 250px;
		width: 20px;
		background-color: #d65200;
		right: 0px;
	}

	.instructions {
		font-size: 16px !important;
		color: #707070 !important;
		padding: 20px 10px !important;
		width: 325px !important;
		text-align: center !important;
		font-family: 'Open Sans' !important;
	}

	.confirmation-content {
		display: flex;
		position: relative;
		justify-content: center;
		padding-top: 0px;
	}

	.confirmation-container {
		height: 100%;
		background-color: #000352;
	}
}

@media screen and (min-device-width: 300px) and (max-device-width: 399px) and (-webkit-min-device-pixel-ratio: 1) {
	.confirmation-inner-container {
		padding: 30px;
	}

	.confirmation-logo {
		height: 75px;
		width: 225px;
	}

	.road-img {
		display: none;
	}

	.img-container {
		display: none;
	}

	.instructions-container {
		height: 250px;
		width: calc(100% - 80px);
		position: relative;
		left: 0px;
		top: 0px;
		background-color: #ffffff;
		z-index: 2;
		border: 1px solid #707070;
		border-radius: 3;
		display: flex;
		justify-content: space-between;
	}

	.orange {
		height: 250px;
		width: 20px;
		background-color: #d65200;
		right: 0px;
	}

	.instructions {
		font-size: 16px !important;
		color: #707070 !important;
		padding: 20px 10px !important;
		width: 250px !important;
		text-align: center !important;
		font-family: 'Open Sans' !important;
	}

	.confirmation-content {
		display: flex;
		position: relative;
		justify-content: center;
		padding-top: 0px;
	}

	.confirmation-container {
		height: 100%;
		background-color: #000352;
	}
}
