.wizard-card {
	width: 1000px;
	margin: -200px auto 50px auto;
	padding-bottom: 20px;
}

.wizard-stepper {
	margin-top: 20px;
	width: 100%;
}

.step-icon-white {
	color: #ffffff;
	border-radius: 50%;
	height: 30px;
	width: 30px;
}

.step-icon-grey {
	color: #707070;
	border-radius: 50%;
	height: 30px;
	width: 30px;
}

.step-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px auto;
	padding-bottom: 10px;
}

.step1-next {
	display: flex;
	justify-content: flex-end;
	max-height: 40px;
	margin: 20px 0px 0px 0px;
}

.step-button-container {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	width: 90%;
	position: relative;
	top: 25px;
}

.step-next-button-icon {
	padding: 0px 0px 12px 5px;
}

.step-back-button-icon {
	padding: 0px 0px 12px 5px;
	transform: rotate(180deg);
}

.white {
	color: #ffffff;
}

.grey {
	color: #00000042;
}

.confirmation {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px auto;
	justify-content: space-between;
	font-family: 'Open Sans';
	height: 80%;
}

.confirmation-label {
	color: #707070;
	font-weight: bold;
	margin-right: 10px;
	font-size: 22px;
}

.confirmation-button-container {
	display: flex;
	justify-content: space-between;
	width: 90%;
	position: relative;
	padding-bottom: 20px;
	top: 25px;
}

.confirmation-value {
	font-size: 22px !important;
}

.confirmation-text-container {
	margin-top: 50px;
}

.complete-container {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.complete-congrats {
	margin-top: 50px;
	color: #d65200;
	font-size: 28px;
	font-family: 'Open Sans';
	font-weight: bold;
	text-transform: uppercase;
}

.complete-cta {
	margin: 50px auto;
	color: #707070;
	font-size: 28px;
	font-family: 'Open Sans';
	font-weight: bold;
	width: 75%;
}

.MuiFormLabel-root {
	color: #707070 !important;
	font-weight: bold !important;
	font-family: 'Open Sans' !important;
}

.step-error {
	color: red;
	padding: 0px 10px;
}

/*-----Desktops/Large Laptops-----*/

/*---Non-Retina Screens---*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.wizard-card {
		height: 450px;
		bottom: 325px;
		overflow-y: auto !important;
	}
	.wizard-stepper {
		margin-top: 10px;
		width: 100%;
	}
	.step1-next {
		position: relative;
		align-self: flex-end;
		top: 16px;
		right: 30px;
	}
	.step1-next-button {
		background-color: #000352 !important;
		color: #ffffff !important;
		max-height: 40px !important;
	}
}

/*---Retina Screens---*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
}

/*-----Small Laptops/Chromebooks-----*/
@media screen and (min-device-width: 800px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
	.wizard-card {
		height: 500px;
		width: 800px;
		bottom: 375px;
		overflow-y: auto !important;
	}
	.wizard-stepper {
		margin-top: 10px;
		width: 100%;
	}
	.step1-next {
		position: relative;
		align-self: flex-end;
		top: 56px;
		right: 30px;
	}
	.step1-next-button {
		background-color: #000352 !important;
		color: #ffffff !important;
		max-height: 40px !important;
	}
}

/*-----Tablets-----*/
@media screen and (min-device-width: 501px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
	.wizard-card {
		height: auto;
		width: 600px;
		overflow: hidden !important;
		margin: -200px auto 50px auto;
	}
	.wizard-stepper {
		margin-top: 10px;
		width: 100%;
	}
	.step1-next-button {
		background-color: #000352 !important;
		color: #ffffff !important;
		max-height: 40px !important;
	}

	.step1-next {
		align-self: flex-end;
		max-height: 40px;
		margin-top: 20px;
	}
}

/*-----Mobile-----*/
@media screen and (min-device-width: 400px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
	.wizard-card {
		height: auto;
		width: calc(100% - 80px);
		overflow: hidden !important;
		margin: -350px auto 50px auto;
	}

	.wizard-stepper {
		margin: 10px auto;
		width: 100%;
	}

	.step1-next-button {
		background-color: #000352 !important;
		color: #ffffff !important;
		max-height: 40px !important;
	}

	.step1-next {
		align-self: flex-end;
		max-height: 40px;
		margin-top: 20px;
	}

	.confirmation {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0px auto;
		justify-content: space-between;
		font-family: 'Open Sans';
		height: 80%;
		padding: 0px 40px;
	}

	.confirmation-label {
		color: #707070;
		font-weight: bold;
		margin-right: 10px;
		font-size: 16px;
	}

	.confirmation-button-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: relative;
		padding-bottom: 20px;
		top: 25px;
	}

	.confirmation-value {
		font-size: 14px !important;
		word-break: break-all;
	}

	.confirmation-text-container {
		margin: 25px auto;
	}

	.complete-container {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
	}

	.complete-congrats {
		margin-top: 50px;
		color: #d65200;
		font-size: 22px;
		font-family: 'Open Sans';
		font-weight: bold;
		text-transform: uppercase;
		width: 90%;
	}

	.complete-cta {
		margin: 50px auto;
		color: #707070;
		font-size: 20px;
		font-family: 'Open Sans';
		font-weight: bold;
		width: 75%;
	}
}

@media screen and (min-device-width: 300px) and (max-device-width: 399px) and (-webkit-min-device-pixel-ratio: 1) {
	.wizard-card {
		height: auto;
		width: calc(100% - 80px);
		padding-bottom: 20px;
		overflow: hidden !important;
		margin: -350px auto 50px auto;
	}

	.wizard-stepper {
		margin: 10px auto;
		width: 100%;
	}

	.step1-next-button {
		background-color: #000352 !important;
		color: #ffffff !important;
		max-height: 40px !important;
	}

	.step1-next {
		align-self: flex-end;
		max-height: 40px;
		margin-top: 20px;
	}

	.confirmation {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0px auto;
		justify-content: space-between;
		font-family: 'Open Sans';
		height: 80%;
		padding: 0px 35px;
	}

	.confirmation-label {
		color: #707070;
		font-weight: bold;
		margin-right: 10px;
		font-size: 16px;
	}

	.confirmation-value {
		font-size: 14px !important;
		word-break: break-all;
	}

	.confirmation-text-container {
		margin: 25px auto;
	}

	.complete-container {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
	}

	.complete-congrats {
		margin-top: 50px;
		color: #d65200;
		font-size: 18px;
		font-family: 'Open Sans';
		font-weight: bold;
		text-transform: uppercase;
		width: 90%;
	}

	.complete-cta {
		margin: 50px auto;
		color: #707070;
		font-size: 16px;
		font-family: 'Open Sans';
		font-weight: bold;
		width: 75%;
	}

	.confirmation-button-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: relative;
		padding-bottom: 20px;
		top: 25px;
	}
}
