.MuiButtonBase-root.MuiButton-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
	background-color: #dadad9 !important;
}

.MuiStepConnector-root {
	top: 10px !important;
	left: calc(-50% + 30px) !important;
	right: calc(50% + 30px) !important;
	height: 3px !important;
	background-color: #707070;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
	margin-top: 10px !important;
	font-family: 'Open Sans' !important;
	font-weight: bold !important;
	color: #707070 !important;
}

.MuiButtonBase-root.MuiButton-root {
	text-transform: none !important;
	font-family: 'Open Sans' !important;
	font-weight: bold !important;
	color: #ffffff !important;
	background-color: #000352 !important;
	height: 40px !important;
}

.Mui-checked {
	color: #d65200 !important;
}

.app {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.app-setHeight {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.MuiTooltip-tooltip {
	font-size: 16px !important;
}

/*-----Tablets-----*/
@media screen and (min-device-width: 501px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
}

/*-----Lg. Mobile-----*/
@media only screen and (min-device-width: 400px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		display: none;
	}
	.MuiButtonBase-root.MuiButton-root {
		text-transform: none !important;
		font-family: 'Open Sans' !important;
		font-weight: bold !important;
		color: #ffffff !important;
		background-color: #000352 !important;
		height: 50px !important;
		font-size: 12px;
	}

	.grecaptcha-badge {
		transform: scale(0.75);
		transform-origin: 0 0;
		right: -203px !important;
		bottom: 0px !important;
	}
}

/*-----Sm Mobile-----*/
@media only screen and (min-device-width: 300px) and (max-device-width: 399px) and (-webkit-min-device-pixel-ratio: 2) {
	.app-setHeight {
		height: 100vh;
	}

	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		display: none;
	}

	.MuiButtonBase-root.MuiButton-root {
		text-transform: none !important;
		font-family: 'Open Sans' !important;
		font-weight: bold !important;
		color: #ffffff !important;
		background-color: #000352 !important;
		height: 50px !important;
		font-size: 12px;
	}

	.grecaptcha-badge {
		transform: scale(0.6);
		transform-origin: 0 0;
		right: -214px !important;
		bottom: 0px !important;
	}
}

/*-----Google Pixel 2-----*/
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-device-pixel-ratio: 2.625) {
}
