.contactInfo-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.contactInfo-row {
	display: flex;
	align-items: center;
	margin-top: 25px;
}

.contactInfo-label {
	width: 120px;
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	font-weight: bold;
	color: #707070;
	font-family: 'Open Sans';
}

.contactInfo-mobile-label {
	display: none;
}

.red {
	color: red;
}

.flex {
	display: flex;
}

.contactInfo-input {
	width: 400px;
}

.radio-group {
	justify-content: space-between;
}

.question-label {
	color: #707070;
	font-weight: bold;
	font-family: 'Open Sans';
	margin: 20px 0px;
}

.question-container {
	width: 80%;
	margin-top: 20px !important;
}

/*-----Desktops-----*/
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
	.MuiOutlinedInput-input {
		padding: 12px 14px !important;
	}
}

/*-----Laptops-----*/
@media screen and (min-device-width: 800px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
	.MuiOutlinedInput-input {
		padding: 12px 14px !important;
	}

	.contactInfo-input {
		width: 350px;
	}
}

/*-----Tablets-----*/
@media screen and (min-device-width: 500px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
	.contactInfo-input {
		width: 325px;
	}
	.MuiOutlinedInput-input {
		padding: 12px 14px !important;
	}
	.contactInfo-mobile-label {
		display: none;
	}
}

/*-----Mobile-----*/
@media screen and (min-device-width: 400px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
	.contactInfo-input {
		width: 250px;
	}

	.contactInfo-label {
		display: none;
	}

	.contactInfo-mobile-label {
		display: block;
	}

	.question-container {
		width: 90%;
	}
}

@media screen and (min-device-width: 300px) and (max-device-width: 399px) and (-webkit-min-device-pixel-ratio: 1) {
	.contactInfo-input {
		width: 200px;
	}

	.contactInfo-label {
		display: none;
	}

	.contactInfo-mobile-label {
		display: block;
	}

	.question-container {
		width: 90%;
	}
}
